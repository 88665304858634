<template>
  <section class="">
    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12 pb-3 ">
        <router-link to="/edit-contact/1">
          <a class="btn m-2 float-right btn  btn-primary">Edit</a>
        </router-link>
      </div>
      <div class="col-lg-4 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0 ">
            <div class="card-title p-3 mb-0 border-bottom">
              Address
            </div>
            <p class="p-3 mb-0 text-center">
              {{ contactDetails.address }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0 ">
            <div class="card-title p-3 mb-0 border-bottom">
              Contact
            </div>
            <p class="p-3 mb-0 text-center">
              {{ contactDetails.email }} <br>
              {{ contactDetails.phone }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-4 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0 ">
            <div class="card-title p-3 mb-0 border-bottom">
              Social
            </div>
            <p class="p-3 mb-0 text-center">
              Linkedin, Instagram, YouTube<br>
              Facebook, Twitter, 
            </p>
          </div>
        </div>
      </div>
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="table-responsive mb-0 pb-3">
            <div class="card-title p-3 mb-0 border-bottom">
              Contact Us Form
            </div>
            <table class="table table-centered table-nowrap border-bottom">
              <thead>
                <tr>
                  <th>No</th>
                  <th>Name</th>
                  <th>Mobile No</th>
                  <th>Email</th>
                  <th>Type</th>
                  <th>Message</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(contact , index) in contacts" :key="contact.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ contact.name }}</td>
                  <td>{{ contact.phone }}</td>
                  <td>{{ contact.email }}</td>
                  <td>{{ contact.type }}</td>
                  <td>{{ contact.message }}</td>
                </tr>
                
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Loader from "../components/Loader";
import { contactApi,sliderApi } from "../api";
export default {
  name: 'contact',
  components: {
  },
  data() {
    return {
      contacts:"",
      showLoader: false, 
      contactDetails: "", 
    };
  },
  mounted() {
    this.getContact();
    this.ContactDetailsById();
  },

  methods: {
    async ContactDetailsById() {
      this.showLoader = true;
      const { data } = await sliderApi.getContactDetailsById(`1`);
      this.contactDetails = data.data.data;
      this.showLoader = false;
    },
    async getContact() {
      this.showLoader = true;
      const { data } = await contactApi.contact();
      this.contacts = data.data.data.contacts;
      this.showLoader = false;
    },
  }
  
}
</script>

<style scoped></style>